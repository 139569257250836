.connect_wallet_modal {
    width: 528px !important;
    height: 198px !important;
    padding: 40px 60px;

    background: #FFFFFF;
    box-shadow: 0px 10px 24px -1px rgba(29, 29, 31, 0.15) !important;
    border-radius: 20px !important;


    .container {
        display: flex;
        flex-direction: column;


        .title {
            width: 100%;
            height: 30px;
            align-self: center;

            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;

            text-align: center;
            letter-spacing: -0.02px;
            color: #3E89FA;

        }

        .item {
            display: flex;
            flex-direction: row;

            width: 408px;
            height: 56px;
            margin-top: 32px;

            background: #FFFFFF;
            border: 1px solid #ECE9F1;
            box-sizing: border-box;
            border-radius: 12px;

            &_icon {
                width: 40px;
                height: 40px;
                margin-top: 8px;
                margin-left: 24px;
            }

            &_text {
                height: 25px;
                margin-top: 16px;

                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 25px;
                padding-left: 16px;

                text-align: center;
                letter-spacing: -0.02px;
                color: rgba(29, 29, 31, 0.87);

            }

            &:hover {
                background-color: whitesmoke;
                cursor: pointer;
            }
        }
    }
}