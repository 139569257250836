.vesting_schedule_modal {
    width: 100%;
    height: 100%;

    .modal_dialog {
        top: 130px !important;
    }

    .modal_dialog,
    .modal_content,
    .wallet-content {
        max-width: 754px !important;
        height: 446px !important;
        border-radius: 12px;
        border: 0;
    }
}

.close_modal {
    position: absolute;
    float: right;
    width: 98%;
    height: 24px;
    margin-top: 16px;
    margin-right: 16px;

    display: flex;
    justify-content: flex-end;
}

.vesting_schedule_modal_body {
    width: 100%;
    height: 100%;
    padding-top: 48px;

    display: grid;
    padding-left: 43px;
    padding-right: 40px;
    padding-bottom: 30px;

    box-shadow: 0px 10px 24px -1px rgba(0, 0, 0, 0.1);
    border-radius: 12px !important;
}

.vesting_schedule_title {
    width: 100%;
    height: 28px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    text-align: center;
    color: #1C1C28;
}

.vesting_schedule_modal_content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: 21px;

    display: flex;
    flex-direction: column;

    &_title {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        text-align: left;
        color: #1C1C28;
    }
}

.list_item_investing {
    width: 100%;
    height: 158px;
    margin-top: 11px;
    padding-left: 20px;
    text-align: left;

    li {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;

        color: rgba(29, 29, 31, 0.87);

        span {
            position: relative;
            left: 0px;
        }

    }
}

.title_boot_portfolio {
    width: 100%;
    height: 28px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;

    text-align: center;
    color: #1C1C28;
}

.form_register {
    background: #FAFAFF;
    border: 1px solid #EEEEFF;
    box-sizing: border-box;
    border-radius: 12px;
    width: 439px;

    padding: 18px 15px 40px 16px;

    .text-label {
        width: 100%;
        height: 28px;
        margin-bottom: 24px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #1C1C28;

    }

    .text-wrapper {
        margin-top: 16px;
    }

    .text-custom {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        color: #1C1C28;
    }
}

.checkbox_register_content {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.register_input_content {
    width: 100%;
    min-height: 56px;
    height: auto;
}

.button_wrapper {
    width: 100%;
    height: 56px;

    display: flex;
    justify-content: space-around;
}

.vesting_login_link {
    width: 315px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(91.08deg, #3E89FA 0%, #4E00CC 100%);
    border-radius: 12px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02px;
    color: #FFFFFF;

    &:hover {
        color: #FFFFFF;
    }
}

.vesting_register_link {
    width: 315px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 28px;

    background: #FFFFFF;
    border: 1px solid #3E89FA;
    box-sizing: border-box;
    border-radius: 12px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02px;
    color: #3E89FA;
}


.vesting_schedule_modal_container {
    width: 100%;
    height: 100%;
}