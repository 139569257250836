.spinner {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
}

.spinner>div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}