.customMain {
  position: fixed;
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D2D2D7;
  }
}

.title-default {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.02px;
  line-height: 30px;
  background: -webkit-linear-gradient(#3E89FA 100%, #4E00CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.span-blur-default {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02px;
  color: #D2D2D7;
}

.text-blur-default {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02px;
  color: rgba(29, 29, 31, 0.6);
}

.logoContainer {
  margin-top: 60px;
}

.flourishingLogo {
  height: 80px;
}

.formRow {
  // height: 554px;
  margin-top: 32px;

  .form-wrapper {
    padding: 40px 60px 42px 60px;

    background: #FFFFFF;
    box-shadow: 0px 10px 24px -1px rgba(62, 137, 250, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px;

    .form-content {
      width: 100%;
      height: 100%;
    }
  }
}

.text-default {
  font-family: SF Pro Text;
  font-style: normal;
}

.error {
  border: 1px solid #E30000;
  background: #FDF2F2;
  color: #E30000;
}

.error::placeholder {
  color: #E30000;
}

.button-wrapper {
  margin-top: 32px;

  .btn-custom {
    width: 408px;
    height: 56px;
    border: none;
    background: linear-gradient(91.08deg, #3E89FA 0%, #4E00CC 100%);
    border-radius: 12px;
    color: #ffffff;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02px;
  }
}

.modal-dialog {
  justify-content: center;
}