.vesting_container {
    width: 946px;
    height: auto;
    padding: 40px !important;

    background: #FFFFFF;
    box-shadow: 0px 10px 24px -1px rgba(62, 137, 250, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px;
}

.vesting_title {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content:right;
}

.vesting_search_container {
    width: 100%;
    height: 56px;
    margin-top: 39px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.vesting_search_content {
    width: 630px;
    height: 58px;

    display: flex;
    align-items: center;

    border: 1px solid #EEEEFF;
    box-sizing: border-box;
    border-radius: 12px;

    svg {
        width: 24px;
        height: 24px;
        margin-left: 24px;
    }


    input {
        width: 95%;
        height: 53px;
        border: none;
        padding-left: 16px;
        border-radius: 12px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        line-height: 25px;
        color: #D2D2D7;
    }
}

.vesting_search_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px 15px;
    border: none;

    width: 212px;
    height: 56px;

    background: linear-gradient(91.08deg, #3E89FA 0%, #4E00CC 100%);
    border-radius: 12px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.02px;
    color: #FFFFFF;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

.vesting_content {
    width: 100%;
    height: 326px;
    padding: 24px;
    box-sizing: border-box;

    border: 1px solid #EEEEFF;
    border-radius: 12px;

    &_title {
        width: 40px;
        height: 20px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    &_row {
        display: table;
        width: 100%;
    }

    &_column {
        float: left;
        width: 50%;
        display: flex;
        flex-direction: column;
    }
}

.vesting_amount {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    &_value {
        width: 300px;
        height: 30px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
    }

    &_label {
        width: 40px;
        height: 20px;
        left: 362px;
        top: 448px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 20px;
        color: rgba(28, 28, 40, 0.38);
    }
}

.amount_item {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
}

.vesting_start_time {
    width: 100%;
    height: 20px;
    margin-top: 30px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 20px;
    color: #1D1D1F;
}

.countdown_time {
    width: 100%;
    margin-top: 12px;

    &_title {
        width: 98px;
        height: 20px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 20px;
        color: #C7C9D9;
    }

    &_container {
        display: flex;
        flex-direction: row;
    }

    &_range {
        width: 54px;
        height: 67.5px;
        padding-top: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;

        // opacity: 0.2;
        border-radius: 4px;
    }

    &_value {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    &_label {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
    }

    &_dot {
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.vesting_next_time {
    width: 100%;
    height: 22px;
    margin-top: 16px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: rgba(28, 28, 40, 0.38);
}

.lockdown_style {
    background: rgba(93, 95, 239, 0.2);
    color: #391A76;
}


.countdown_style {
    background: rgba(227, 0, 0, 0.2);
    color: #E30000;
}

.countdown_time_end {
    width: 100%;
    height: 67.5px;

    display: flex;
    justify-items: center;
    align-items: center;

    &_label {
        width: 100%;
        height: 25px;
        margin-left: 10px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        line-height: 25px;
        color: #3ADA81;

    }
}

.group_ellipse {
    position: absolute;
    width: 230px;
    height: 230px;
    bottom: 0;
    right: 0;

    &_container {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
    }

    &_1 {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;
        opacity: 0.2;

        border: 1px solid #3ADA81;
        box-sizing: border-box;
    }

    &_2 {
        position: absolute;
        width: 168.67px;
        height: 168.67px;

        border-radius: 50%;
        background: #3ADA81;
        opacity: 0.3;

        border: 1px solid #3ADA81;
        box-sizing: border-box;
    }
}

.boost_connect_dashboard {
    position: absolute;
    width: 122.67px;
    height: 122.67px;
    border-radius: 50%;
    background: #3ADA81;

    display: flex;
    align-items: center;
    justify-content: center;

    &_text {
        width: 111px;
        height: 75px;

        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        label {
            margin: 0 !important;
        }
    }
}

.vesting_schedule_label {
    width: 160px;
    height: 28px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}

.vesting_schedule_connect_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px 15px;
    border: none;

    width: 212px;
    height: 56px;
    background: linear-gradient(91.08deg, #3E89FA 0%, #4E00CC 100%);
    border-radius: 12px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.02px;
    color: #FFFFFF;
}

.import_token {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: 200px;
    height: 56.2px;
    margin-right: 12px;

    border: 1px solid #3E89FA;
    box-sizing: border-box;
    border-radius: 12px;
}

.import_token_text {
    width: 193px;
    height: 25px;

    font-family: 'SF Pro Text';
    font-style: normal;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.02px;
    color: #3E89FA;
}

.vesting_schedule_address {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px 15px;

    width: 212px;
    height: 56.2px;

    border: 1px solid #3E89FA;
    box-sizing: border-box;
    border-radius: 12px;
}

.vesting_address {
    width: 105px;
    height: 25px;

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.02px;
    color: #3E89FA;
    margin: 0px 16px;
}

.vesting_is_connected {
    width: 100%;
    height: 326px;
    margin-top: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #FAFAFF;
    border: 1px solid #EEEEFF;
    box-sizing: border-box;
    border-radius: 12px;
}

.vesting_is_connected_no_schedule {
    width: 100%;
    height: 326px;
    margin-top: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #FAFAFF;
    border: 1px solid #EEEEFF;
    box-sizing: border-box;
    border-radius: 12px;
}

.vestingdata_show {
    width: 100%;
    min-height: 400px;
    height: auto;
}

.vestingdata_show_content {
    width: 100%;
    min-height: 400px;
    height: auto;
}

.vestingdata_show_content>*+* {
    margin-top: 39px;
}

.vesting_login_link:hover {
    color: #FFFFFF;
}

.vestingdata_show_total {
    width: 100%;
    height: 20px;
    margin-bottom: 4px;

    span {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        color: rgba(29, 29, 31, 0.6);
    }

    label {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        color: #3ADA81;
    }
}

.vestingdata_no_schedule {

    margin-bottom: 4px;

    span {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: rgba(29, 29, 31, 0.6);
    }

    label {
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #3ADA81;
    }
}

.vesting_container_default {
    min-height: 486px;
}

.vesting_container_no_schedule {
    min-height: 500px;
}

.vesting_container_has_one_schedule {
    min-height: 500px;
}

.boost_connect_dashboard:hover {
    cursor: pointer;
}

.boost_connect_dashboard>label:hover {
    cursor: pointer;
    pointer-events: auto;
}

.boost_connect_dashboard-text {
    label:hover {
        cursor: pointer;
    }

    label {
        width: 114px;
        display: -moz-inline-stack;
        display: inline-block;
    }
}

.flourishing_logo:hover {
    cursor: pointer;
}