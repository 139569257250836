@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Text';
}

input:focus {
  outline: none;
}

.custom-focus:focus {
  border: none !important;
  box-shadow: none !important;
}

.custom-icon {
  width: 17px;
  height: 18px;
  margin: 12px;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets//fonts//SProText.ttf') format('truetype');
}

.default-bg {
  background: url("./assets/default-bg.png");
  background-size: cover;
}

.default-gradient {
  color: linear-gradient(296.43deg, #4E00CC -27.46%, #3E89FA 98.06%);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #EDEEF4;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #C7C9D9;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #C7C9D9;
}

.border-error {
  border: 1px solid #E30000;
}

.text-error {
  color: #E30000;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--placeholder-color);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--placeholder-color);
}

::placeholder {
  color: var(--placeholder-color);
  opacity: 0.8;
}

input[type=checkbox] {
  -moz-appearance: initial
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.public-area {
  width: 100%;
  height: 100%;
}

.public-area-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-dialog {
  top: 150px;
  display: flex;
  justify-content: center;
  z-index: 1000;
}