.toast-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    display: inline-flex;
}

.toast-content-left {
    width: 24px;
    height: 24px;
}

.toast-message {
    margin-left: 16px;
    font-family: 'SF Pro Text' !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #FFFFFF;
}

.Toastify__toast--success {
    height: 76px;
    width: 255px;
    border-radius: 10px !important;
    padding-left: 24px;
    background-color: #3ADA81 !important;

    border: 1px solid #3ADA81;
    box-sizing: border-box;
    box-shadow: 0px 10px 24px -1px rgba(62, 137, 250, 0.25);
}

.Toastify__toast--error {
    height: 76px;
    width: 255px;
    border-radius: 10px !important;
    padding-left: 24px;
}